import styled from "styled-components"

export const CommentsContainer = styled.div`
  min-width: 240px;
  max-width: 700px;

  .utterances {
    margin: 0;
  }
`
